<template>
    <transition name="fade-in-up">
      <router-view>shiftWorkConfig page</router-view>
    </transition>
  </template>
  
  <style lang="scss" scoped>
  @import '~bootstrap-vue/dist/bootstrap-vue.css';
  </style>
  
  <script>
  import { SET_MENU } from '@/core/services/store/modules/menu.module';
  import { SideBar } from '@/core/config/menu/sideBar';
  export default {
    components: {},
    mounted() {
      this.$store.dispatch(SET_MENU, SideBar.STORE);
    },
    methods: {}
  };
  </script>